import CryptoJS from 'crypto-js';

const SECRET_KEY = 'jhdwsnbxs56weoiasdcgou7wehkjascywehfpiasdfiqweyrilachbpq9erbp1qeiyfq873or8weoqo872316rp98qycq73rcp87qwerc623r6bpiwuqedbc8726erycq8w7eq827etcqwkhjdcgq2039ecq2peiwgoweirqc3892r7tcqoetfywcefwo73yeqw98cfigwesfkycegwofiugweofuycgqweuf'; // clave secreta para encriptación y desencriptación

// Método para encriptar un String
export function encryptString(text) {
  const encryptedText = CryptoJS.AES.encrypt(text, SECRET_KEY).toString();
  return encodeURIComponent(encryptedText);
}

// Método para desencriptar un String
export function decryptString(encryptedText) {
  const decodedText = decodeURIComponent(encryptedText);
  const bytes = CryptoJS.AES.decrypt(decodedText, SECRET_KEY);
  const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedText;
}